import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import NewsletterSignup from "../components/newsletterSignup"

export const query = graphql`
  query {
    craft {
      entry(section: "newsletter") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_newsletter_newsletter_Entry {
          body
          alternateTitle
        }
      }
    }
  }
`

const NewsletterPage = ({ data }) => {
  const entry = data.craft.entry
  const seo = entry.seomatic
  let title = entry.alternateTitle != null ? entry.alternateTitle : entry.title

  return (
    <Layout seo={seo}>
      <div className="page-content-centered">
        <div className="wrapper">
          <div className="columns is-centered">
            <div className="column is-12 is-8-tablet is-6-desktop is-4-widescreen">
              <div className="mb-5">
                <p
                  className="h-2 h--cms-title text-center"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              </div>
              <div
                className="mb-5 user-content text-center"
                dangerouslySetInnerHTML={{
                  __html: entry.body,
                }}
              />
              <NewsletterSignup />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsletterPage
